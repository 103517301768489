import React from "react"

import Layout from "../components/layout"
import Head from "../components/head"
import PageHeading from "../components/pageHeading"
import MiniHeading from "../components/miniHeading"
import Button from "../components/button"
import Markdown from "../components/Markdown"

import whatWeCanDo from "../cms/what-we-can-do"

export default () => (
  <Layout>
    <Head title="What We Can Do" />

    <PageHeading text="What We Can Do" centered />

    {whatWeCanDo.topics.map(data => (
      <React.Fragment key={data.title} >
        <MiniHeading text={data.title} />
        
        <Markdown raw={data.text} />

        {data.title.toLowerCase().includes("organizations") ? 
          <Button
            to="/organizations/"
            text="Organizations"
            style={`
              margin-top: 1rem;
            `}
          />
        : ""}
      </React.Fragment>
    ))}
  </Layout>
)