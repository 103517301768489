import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

export default props => (
  <Link
      to={props.to}
      css={css`
        text-decoration: none;
        margin-top: 3rem;
        display: block;
        max-width: max-content;
        background-color: var(--background-dark-secondary);
        padding: .7rem 1.3rem;
        border-radius: 99rem;
        color: white;
        font-weight: 500;
        font-size: .9rem;
        user-select: none;

        transition: opacity .15s;

        :hover {
          opacity: .9;
        }

        ${props.style}
      `}
    >
      {props.text} ⟶
    </Link>
)