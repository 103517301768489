import React from "react"
import { css } from "@emotion/core"

export default props => (
  <h3
    css={css`
      color: var(--text-light);
      font-weight: 700;
      font-size: 1.2rem;
      margin-top: 2rem;
      margin-bottom: 1rem;
      ${props.centered ? "text-align: center;" : ""}

      ${props.style}
    `}
  >
    {props.text}
  </h3>
)
